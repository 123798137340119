<template>
  <div class="person" :class="classes">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    button: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        button: this.button
      };
    }
  }
};
</script>

<style>
.person {
  width: 100%;
  background-color: #26c6da;
  font-size: 22px;
  line-height: 60px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  text-transform: capitalize;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}
.button {
  background-color: #fff;
  color: #2a3138;
}

/* @media only screen and (max-width: 576px) {
  .person {
    font-size: 14px;
  }
}

@media only screen and (max-width: 411px) {
  .person {
    font-size: 14px;
  }
} */
</style>
