<template>
  <div class="picker-wrap" v-if="activeMode === modes[0]">
    <div
      class="list-container"
      :class="{ 'list-container--active': listHasSelection }"
    >
      <picker-button
        v-for="(item, index) in people"
        :key="index"
        :class="{ 'person--active': isActive(item.email) }"
        @click.native="toggleAsSelected(item.email)"
        >{{ item.firstName }}</picker-button
      >
    </div>
    <div class="buttons-container">
      <picker-button
        v-for="(button, index) in buttons"
        :key="index"
        button
        @click.native="setMode(button)"
        >{{ button }}</picker-button
      >
    </div>
  </div>
  <div class="randomizer" v-else>
    <button class="button-randomize" @click="setRandomEmployee">
      Randomize
    </button>
    <button class="button-quit-randomizer" @click="setMode(buttons[0])">
      Quit
    </button>
  </div>
</template>

<script>
import {
  fetchPeople,
  setAsSelectedPerson,
  setSelectedMode,
  subscribeSelectedPerson,
  setRandomizedEmployee
} from "@/api";

import PickerButton from "../components/Picker/PickerButton";

export default {
  components: {
    PickerButton
  },

  data() {
    return {
      people: [],
      activeButton: null,
      buttons: ["standup", "panic", "randomizer"],
      unsubscribe: null,
      activeMode: "picker",
      modes: ["picker", "randomizer"],
      selectableNumbers: [],
      randomizedEmployee: {},
      randomizeButtonDisabled: false,
      randomizerFinished: false
    };
  },

  computed: {
    selectedOffice() {
      return this.$route.params.office;
    },
    listHasSelection() {
      return this.activeButton !== null;
    }
  },

  methods: {
    isActive(value) {
      return value === this.activeButton;
    },
    toggleAsSelected(email) {
      if (this.activeButton !== email) {
        this.activeButton = email;
        setAsSelectedPerson(email, this.selectedOffice);
      } else {
        this.activeButton = null;
        setAsSelectedPerson("", this.selectedOffice);
      }
    },
    setMode(value) {
      setSelectedMode(value, this.selectedOffice);

      if (value === this.modes[1]) {
        this.activeMode = this.modes[1];
        // Reset selectable people array for randomizing
        this.selectableNumbers = [...Array(this.people.length).keys()];
        setRandomizedEmployee({ finished: false }, this.selectedOffice);
      } else {
        this.activeMode = this.modes[0];
        this.randomizerFinished = false;
      }
    },
    setRandomEmployee() {
      if (!this.randomizerFinished && !this.randomizeButtonDisabled) {
        this.randomizeButtonDisabled = true;
        const rand = this.selectableNumbers[
          Math.floor(Math.random() * this.selectableNumbers.length)
        ];

        if (this.selectableNumbers.length !== 0) {
          this.randomizedEmployee = this.people[rand];

          // Set employee in firebase
          setRandomizedEmployee(this.randomizedEmployee, this.selectedOffice);
        } else {
          setRandomizedEmployee({ finished: true }, this.selectedOffice);
          this.randomizerFinished = true;
        }

        // remove the new number from selectable numbers
        this.selectableNumbers = this.selectableNumbers.filter(
          el => el !== rand
        );

        setTimeout(() => {
          this.randomizeButtonDisabled = false;
        }, 2000);
      }
    }
  },

  async created() {
    this.people = await fetchPeople(this.selectedOffice);
    this.unsubscribe = subscribeSelectedPerson(this.selectedOffice, value => {
      this.activeButton = value;
    });

    // Change site viewport width
    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=768");

    // for randomizer
    this.selectableNumbers = [...Array(this.people.length).keys()];
  },

  mounted() {},

  beforeDestroy() {
    this.unsubscribe();

    document
      .querySelector('meta[name="viewport"]')
      .setAttribute("content", "width=device-width,initial-scale=1.0");
  }
};
</script>

<style scoped>
::-webkit-scrollbar {
  display: none;
}
.picker-wrap,
.randomizer {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #212121;
  background-image: linear-gradient(#323a44, #242a32);
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.list-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  padding: 40px;
}

.buttons-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: 20px;
  grid-column-gap: 20px;
  padding: 40px;
  width: 100%;
  border-top: 2px solid #fff;
  background: #212121;
  position: fixed;
  bottom: 0;
}

.list-container--active .person {
  opacity: 0.4;
}
.list-container--active .person--active {
  opacity: 1;
}

.randomizer {
  align-items: center;
  justify-content: center;
}

.button-randomize {
  width: 200px;
  height: 100px;
  font-size: 32px;
  transition: all 0.1s ease-in-out;
}

.button-quit-randomizer {
  margin-top: 32px;
  height: 50px;
  width: 200px;
  font-size: 24px;
  transition: all 0.1s ease-in-out;
}

@media screen and (min-width: 768px) {
  .button-quit-randomizer:hover,
  .button-randomize:hover {
    background-color: #26c6da;
    color: #fff;
    cursor: pointer;
    box-shadow: 0 12px 24px -12px rgba(0, 0, 0, 1);
  }
}

.button-quit-randomizer:active,
.button-randomize:active {
  transform: translateY(5px);
}
</style>
